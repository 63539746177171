<template>
  <div class="has-nav-bar view-workticket">
    <WorkticketHeader title="作业许可查询"></WorkticketHeader>
    <div>
      <van-search
        v-model="query.ticketInfo"
        placeholder="请输入名称关键词或编号"
        @search="getList(true)"
        @clear="getList(true)"
      />
      <date-time
        v-model="dateTime"
        :time-close="true"
        type="date"
        format="{y}-{m}-{d}"
        @input="getList(true)"
      />
      <work-query :query="query" @refresh="onQueryChange"></work-query>
    </div>
    <LoadListView
      v-model="loadStatus"
      :list="list"
      :total="total"
      @load="getList"
    >
      <WorkticketItem
        v-for="(item, index) in list"
        :id="item.id"
        :key="index"
        :item="item"
        :class="{ 'block-space_8': index < list.length - 1 }"
        :title="item.name"
        :status="item.statusName"
        :types="item.workType"
        :number="item.code"
        :start-time="item.planStart"
        :end-time="item.planEnd"
        :text1="item.departmentName"
        :text2="item.facilityName"
        :text3="item.supervisorList"
        :text4="item.approverList"
        :display-terminal-time="item.displayTerminalTime"
        :terminal-time="item.terminalTime"
        :alarm-num="item.alarmNum"
        :warning-flag="item.warningFlag"
      />
    </LoadListView>
  </div>
</template>

<script>
import WorkticketHeader from "@/components/header/WorkticketHeader";
import WorkticketItem from "@/components/workticket/WorkticketItem";
import LoadListView from "@/components/LoadListView";
import { getWorkticketList } from "@/api/workticket";
import { getTimeParseDate } from "@/utils";
import DateTime from "@/components/DatetimePicker";
import WorkQuery from "@/views/workticket/components/WorkQuery";
import dayjs from "dayjs";

export default {
  name: "Workticket",
  components: {
    WorkQuery,
    DateTime,
    LoadListView,
    WorkticketItem,
    WorkticketHeader
  },
  data() {
    const _startTime = dayjs()
      .add(-7, "day")
      .format("YYYY-MM-DD");
    const _endTime = dayjs().format("YYYY-MM-DD");
    return {
      dateTime: {
        start: _startTime,
        end: _endTime
      },
      loadStatus: "loading",
      list: [],
      total: 0,
      query: {
        size: 10,
        page: 1,
        ticketInfo: "",
        queryTimeStart: "",
        queryTimeEnd: "",
        workType: "",
        workStatus: "",
        department: "",
        poiId: "" //作业区域
      },
      departmentLabel: "",
      areaLabel: "",
      typeLabel: "",
      statusLabel: "",
      departmentVisible: false
    };
  },
  computed: {
    workTypeOptions() {
      return this.$store.state.workticket.workTypeOptions;
    },
    workStatusOptions() {
      return this.$store.state.workticket.workStatusOptions;
    },
    userInfo() {
      return this.$store.state.login.userInfo;
    }
  },
  created: function() {},
  mounted: function() {
    this.getList(true);
    this.$store.dispatch("getWorkTypeOptions");
    this.$store.dispatch("getWorkStatusOptions");
  },
  methods: {
    onQueryChange(query) {
      this.query = { ...this.query, ...query };
      this.getList(true);
    },
    /**
     * 是否是刷新,重新加载第一页
     * @param refresh
     */
    getList(refresh = false) {
      if (refresh) {
        this.query.page = 1;
        this.list = [];
        this.loadStatus = "loading";
      }

      getWorkticketList({
        ...this.query,
        orgCode: this.userInfo.orgCode,
        queryTimeStart: getTimeParseDate(
          this.dateTime.start && this.dateTime.start + " 00:00:00"
        ),
        queryTimeEnd: getTimeParseDate(
          this.dateTime.end && this.dateTime.end + " 23:59:59"
        )
      })
        .then(({ total, list }) => {
          list = list.map(i => {
            if (
              i.displayTerminalTime &&
              i.terminalTime &&
              typeof i.terminalTime === "string"
            ) {
              let t = Date.parse(i.terminalTime) - Date.now();
              i.terminalTime = t > 0 ? t : 0;
            }
            return i;
          });
          this.list = this.list.concat(list);
          this.total = total;
          this.loadStatus = "success";
          if (list && list.length > 0) {
            this.query.page++;
          }
        })
        .catch(error => {
          console.log(error);
          this.loadStatus = "error";
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
